module.exports = {
    siteTitle: 'Zion Tech', // <title>
    manifestName: 'Zion',
    manifestShortName: 'Landing', // max 12 characters
    manifestStartUrl: '/',
    manifestBackgroundColor: '#663399',
    manifestThemeColor: '#663399',
    manifestDisplay: 'standalone',
    manifestIcon: 'src/assets/img/website-icon.svg',
    pathPrefix: `/gatsby-starter-phantom/`, // This path is subpath of your hosting https://domain/portfolio
    heading: 'Anubhav Srivastava',
    subHeading: 'Full time Web Developer. Part time Open source contributor  ',
    // social
    socialLinks: [
        {
            icon: 'fa-instagram',
            name: 'Instagram',
            url: '',
        },
        {
            icon: 'fa-twitter',
            name: 'Twitter',
            url: '',
        },
        {
            icon: 'fa-facebook',
            name: 'Facebook',
            url: '',
        },
    ],
};
